import Banner2 from "../../assets/images/Logo.png";

const Header_top = () => {
  const handleBuyGorilla = () => {
    window.open("https://raydium.io/swap/?inputCurrency=sol&outputCurrency=GN51NgMU2HuzPbRiQrHbetjv9whagZVxPPLei8nFizSw&fixed=in", "_blank"); // Replace "https://example.com/buy-gorilla" with your actual buy link
  };

  const handleJoinCommunity = () => {
    window.open("https://t.me/GorillaGemsCoinPortal", "_blank"); // Replace "https://example.com/join-community" with your actual community link
  };

  return (
    <div className="relative pt-10 pb-10 bg-[#172c3b] cardPattern-bg mt-14">
      <div className="grid grid-cols-6 px-14">
        <div className="flex justify-center items-center col-span-3">
          <div>
            <h1 className="text-white text-6xl font-[] text-left px-10 font-bold uppercase">Welcome to <p className="text-[#2b9fbf] text-8xl ">$GorillaGems</p> </h1>
            <p className="text-white font-[RobotoBold] text-left px-10 pt-5 text-2xl">
              Get ready to unleash the power of GorillaGems! The Gorilla, Frank is his name, is ready to share from his treasury of gems! COMMUNITY FORUM!
            </p>
            <div className="flex justify-center items-center pt-10 px-10">
              <button onClick={handleBuyGorilla} className="bg-[#2b9fbf] text-white text-2xl w-full font-bold p-4 rounded-lg uppercase hover:scale-105 ease-in-out duration-300 shadow-2xl" style={{ minWidth: '150px' }}>Buy $Gorilla</button>
              <button onClick={handleJoinCommunity} className="bg-[#2b9fbf] text-white text-2xl w-full font-bold p-4 rounded-lg ml-4 uppercase hover:scale-105 ease-in-out duration-300 shadow-2xl" style={{ minWidth: '150px' }}>Join community</button>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mr-10 rounded-full col-end-7 col-span-2">
          <img src={Banner2} alt="hero1" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default Header_top;
