import Banner2 from "../../assets/images/One_Big_Family.png";

const Header_under = () => {
  return (
    <div className="relative pt-10 pb-10 px-10 bg-[#213f53]">
      <div className="grid grid-cols-2">
      <div className="flex justify-center items-center p-10">
          <img src={Banner2} alt="hero1" className="w-3/4 rounded-2xl shadow-2xl" />
        </div>
        <div className="flex justify-center items-center">
          <div>
            <h1 className="text-white font-[RobotoBold] text-8xl text-center px-10 font-bold">About us</h1>
            <p className="text-white text-left font-thin px-10 pt-5 text-2xl">
            Introducing Goshilla, a dynamic forum driven by the power of $GORILLA tokens. Here, you can showcase and promote your tokens by boosting them to the front page for a nominal fee. This fee is calculated in $GORILLA tokens, for instance, 0.1 Solana equivalent in $GORILLA.
            Your contribution directly fuels our marketing efforts, occasional token burning, and exciting community giveaways from our dedicated marketing wallet. Goshilla is designed as a free-to-use forum with optional "pay-to-win" enhancements. Stay tuned for upcoming features like banners, slideshows, and more to elevate your token promotion experience!
            </p>
          </div>
        </div>
        
      </div>
    </div>
  );
};


export default Header_under;
