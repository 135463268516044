import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from "../../assets/images/Bil_1.jpeg";
import image2 from "../../assets/images/Bil_2.jpeg";
import image3 from "../../assets/images/Bil_8.png";
import image4 from "../../assets/images/Bil_4.jpeg";
import image5 from "../../assets/images/Bil_5.jpeg";
import image6 from "../../assets/images/Bil_6.jpg";
import image7 from "../../assets/images/Bil_7.png";
import image8 from "../../assets/images/Bil_3.jpeg";
import image9 from "../../assets/images/Bil_9.png";
import image10 from "../../assets/images/Bil_10.png";
import image11 from "../../assets/images/Bil_11.png";

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11];
const authors = ["Art by: CryptoForLife", "Art by Herjung", "Art by Herjung", "Art by LudoForLife", "Art by Mikkelmmc", "Art by Huskey", "Art by NiceX54", "Art by HelixArty", "Art by D", "Art by D", "Art by D"];

const Community = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    arrows: false,
    draggable: false,
    touchMove: false,
  };

  return (
    <div className=" bg-gradient-to-t px-2 from-[#213f53] to-[#172c3b]">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2 rounded-lg relative hover:scale-105 ease-in-out duration-300">
            <img src={image} alt={`hero${index + 1}`} className="w-full rounded-2xl" />
            <p className="absolute bottom-0 left-0 right-0 px-2 py-1 bg-opacity-70 bg-[#213f53] text-white text-md tracking-widest font-medium">
              {authors[index]}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Community;
