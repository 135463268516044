import Logo from "../../assets/images/Logo.png";
import Icon from '@mdi/react';
import { mdiTwitter } from '@mdi/js';
import { mdiChat } from '@mdi/js';
import { SocialIcon } from 'react-social-icons';


const NavigationBar = () => {
  return (
    <nav className="bg-[#172b3a] p-4 shadow-xl fixed top-0 left-0 right-0 z-[9999] mb-10 flex justify-between items-center">
      <div className="flex items-center px-16">
        <img src={Logo} alt="logo" className="w-10" />
      </div>
      <div>
        <ul className="flex gap-8 pr-[7rem] ">
          <li>
            <a href="/" className="text-white font-bold text-lg hover:bg-[#2b9fbf] p-2 rounded-md ease-in-out duration-300">HOME</a>
          </li>
          <li>
            <a href="#About" className="text-white font-bold text-lg hover:bg-[#2b9fbf] p-2 rounded-md ease-in-out duration-300">ABOUT</a>
          </li>
          <li>
            <a href="https://twitter.com/GorillaGemsCoin" target="_blank" className="text-white font-bold text-lg hover:bg-[#2b9fbf] p-2 rounded-md ease-in-out duration-300">TWITTER</a>
          </li>
          <li>
            <a href="https://t.me/GorillaGemsCoinPortal" target="_blank" className="text-white font-bold text-lg hover:bg-[#2b9fbf] p-2 rounded-md ease-in-out duration-300">TELEGRAM</a>
          </li>
          
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;
