// Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className=" bg-[#1f2937] text-white">
      <div className='flex h-44 justify-center items-center'>
        <h1>Frank's copyright 2024</h1>
      </div>
    </footer>
  );
};

export default Footer;
