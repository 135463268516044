import React from 'react';
import Footer from '../components/footer/footer';
import Header from '../components/header/header_under';
import Header_top from '../components/topheader/header_top';
import Community from '../components/community/community';
import NavigationBar from '../components/navigationbar/navigationbar';

const SimplePage: React.FC = () => {
    return (
        <div className='overflow-auto'>
            <div className='w-[100vw] h-[100vh] fixed top-0 left-0 right-0 bottom-0 -z-10 bg-[#172c3b]'/>
            <NavigationBar />
            <Header_top />
            <div id="Community">
                <Community />
            </div>
            <div id="About">
                <Header />
            </div>
            <Footer />
        </div>
    );
}


export default SimplePage;
